import { styled } from '@mui/material/styles';

import { Theme } from '../../theme';
import { Paragraph } from '../Paragraph';

export const StyledLabel = styled('label')<{ $hasPaddingTop: boolean }>`
    display: block;
    margin-bottom: 0.5rem;
    padding-top: ${({ $hasPaddingTop }) => $hasPaddingTop && '2rem'};
    font-weight: normal;
    font-size: 1.4rem;
    text-transform: lowercase;
    ${({ theme }: { theme: Theme }) => theme.breakpoints.up('desktop')} {
        font-size: 1.6rem;
    }
`;

export const StyledSelectLabel = styled(StyledLabel, {
    shouldForwardProp: property =>
        typeof property === 'string' && !property.startsWith('$'),
})<{
    $hasPaddingTop: boolean;
    $disabled: boolean;
    $isLabelInOneLine: boolean;
}>`
    width: ${({ $isLabelInOneLine }) => ($isLabelInOneLine ? '100%' : '40rem')};
    padding-top: ${({ $hasPaddingTop }) => $hasPaddingTop && '2rem'};
    ${({ $disabled }) => ($disabled ? 'opacity: 0.4;' : '')}
    ${({ theme }: { theme: Theme }) => theme.breakpoints.down(403)} {
        width: ${({ $isLabelInOneLine }) =>
            $isLabelInOneLine ? '100%' : '25rem'};
    }
    ${({ theme }: { theme: Theme }) => theme.breakpoints.up('desktop')} {
        width: ${({ $isLabelInOneLine }) =>
            $isLabelInOneLine ? '100%' : '35rem'};
    }
`;

export const StyledSmallText = styled(Paragraph)`
    margin: 0;
    padding-top: 1rem;
    font-size: 1rem;
    line-height: 1.5;
    letter-spacing: normal;
    ${({ theme }: { theme: Theme }) => theme.breakpoints.up('desktop')} {
        font-size: 1.2rem;
    }
`;

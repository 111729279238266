/* istanbul ignore file */
import { TextField } from '@mui/material';
import { styled } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

import { palette } from '../../../theme';
import { TextInputProperties } from './TextInput.types';

const errorStyle = () => `
    border-color: ${palette.error.main};
    box-shadow: 0 0 0 0.1rem ${palette.error.main};
`;

const greyStyle = (isInvalid?: boolean) => `
    opacity: 0.4;
    background-color: ${palette.grey['50']};
    ${isInvalid ? errorStyle() : `border: none;`}
`;

const whiteStyle = (isInvalid?: boolean) => `
    background-color: ${palette.common.white};
    ${isInvalid ? errorStyle() : `border-color: ${palette.common.blue};`}
`;

const transparentStyle = (isInvalid?: boolean) => `
    background-color: transparent;
    ${isInvalid ? errorStyle() : `border-color: transparent;`}
`;

const variantStyles = {
    grey: greyStyle,
    white: whiteStyle,
    transparent: transparentStyle,
};

export const StyledTextInputContainer = styled('div')<{
    $variant: NonNullable<TextInputProperties['variant']>;
    $isInvalid: TextInputProperties['isInvalid'];
    $multiline: TextInputProperties['multiline'];
}>`
    display: inline-flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    ${({ $multiline }) =>
        !$multiline &&
        `
    height: 4.4rem;
  `}
    padding: 0.9rem 1.5rem;
    border-style: solid;
    border-width: 0.1rem;
    border-radius: 2.5rem;
    ${({ $variant, $isInvalid }) => variantStyles[$variant]($isInvalid)}
    ${({ $variant }) =>
        $variant === 'grey'
            ? `
                  svg g {
                      fill: ${palette.grey['900']};
                  }
              `
            : ''}
`;

export const StyledTextInput = styled(TextField)`
    flex-direction: unset;
    width: 100%;
    padding-left: 1rem;
    color: ${palette.common.blue};
    font-size: 1.6rem;
    background: none;
    border: none;
    outline: none;
    input,
    fieldset {
        padding: 0;
        border: none;
    }

    ${({ multiline }) =>
        multiline
            ? `
            .MuiOutlinedInput-multiline {
                padding: 0.5rem 0;
            }
        `
            : ``}

    ${({ select }) =>
        select
            ? `
    svg.MuiSelect-iconOutlined{
        right:0;
    }

    select {
        width: 100%;
        padding: 0.9rem 0rem;

        scrollbar-color: ${palette.primary.main}
            transparent;
        &::-webkit-scrollbar {
            width: 0.6rem;
        }
        &::-webkit-scrollbar-track {
            background-color: ${palette.grey[100]};
        }
        &::-webkit-scrollbar-thumb {
            background-color: ${palette.primary.main};
            border-radius: 0.2rem;
        }

        :focus {
            background-color: unset;
        }
    }`
            : ``}
`;

export const customStyles = makeStyles(theme =>
    createStyles({
        formControl: {
            width: '100%',
        },
        input: {
            color: palette.common.blue,
            '&::placeholder': {
                color: palette.common.blue,
                opacity: 1,
            },
        },
    }),
);

export const StyledErrorMessage = styled('div')`
    margin-top: ${({ theme }) => theme.spacing(5)};
    color: ${palette.error.main};
    text-transform: lowercase;
`;

export const StyledLabel = styled('label')<{
    $variant?: NonNullable<TextInputProperties['variant']>;
}>`
    display: inline-block;
    margin-bottom: 0.5rem;
    color: ${palette.common.blue};
    font-weight: normal;
    text-transform: lowercase;
    ${({ $variant }) => ($variant === 'grey' ? 'opacity: 0.4;' : '')}
`;

export const StyledSideIconContainer = styled('span')`
    line-height: 0;
`;

import { Controller } from 'react-hook-form';

import { Asterisk } from '../../Asterisk';
import { TextInput } from '../../Forms/TextInput';
import { OnlyNameMandatory } from '../../Forms/TextInput/TextInput.types';
import { StyledLabel } from '../ControlledInputs.styled';
import { ControlledEmailInputProperties } from './ControlledEmailInput.types';
import { isValidEmail } from '@tgg/form-validation';

const errorMessage = 'Enter a valid email address';

export const validateEmailAddress = (value: string): string | boolean =>
    isValidEmail(value) || errorMessage;

export function ControlledEmailInput({
    label,
    fieldName = 'email',
    control,
    placeholder = 'Email',
    dependentInputs,
    iconElementRight,
    ...inputProperties
}: ControlledEmailInputProperties) {
    return (
        <Controller
            name={fieldName}
            control={control}
            rules={{
                required: errorMessage,
                validate: {
                    validator: validateEmailAddress,
                },
                deps: dependentInputs,
            }}
            render={({
                field: { value, onChange, onBlur },
                fieldState: { invalid, isDirty, error },
            }) => {
                const defaultIconElementRight = {
                    name: !invalid && isDirty ? 'tick' : 'blank',
                } as OnlyNameMandatory;

                return (
                    <>
                        {label && (
                            <StyledLabel
                                $hasPaddingTop
                                data-testid="email-label"
                                htmlFor={fieldName}
                            >
                                {label}
                                <Asterisk spaceBefore />
                            </StyledLabel>
                        )}
                        <TextInput
                            required
                            value={value}
                            errorMessage={error?.message}
                            isInvalid={invalid}
                            placeholder={placeholder}
                            id="email"
                            onChange={onChange}
                            onBlur={onBlur}
                            iconElementRight={
                                iconElementRight ?? defaultIconElementRight
                            }
                            {...inputProperties}
                        />
                    </>
                );
            }}
        />
    );
}

export default ControlledEmailInput;

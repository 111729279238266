import { StyledAsterisk } from './Asterisk.styled';
import { AsteriksProperties } from './Asterisk.types';

export function Asterisk({
    spaceBefore,
    spaceAfter,
    color,
}: AsteriksProperties) {
    return (
        <StyledAsterisk $color={color} data-testid="styled-asterisk">
            {spaceBefore && ' '}*{spaceAfter && ' '}
        </StyledAsterisk>
    );
}
